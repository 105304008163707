import moment from "moment";
import React from "react";
// import SmartTable from "../../Component/Common/SmartTable";
import "./invoice.css";
import price from "../../helpers/calculatePrice";
// const printheaders = [
//   { title: "SKU", key: "sku", type: "text", currency: false },
//   { title: " Item", key: "product", type: "text", currency: false },

//   {
//     title: "Unit Price",
//     key: "unitPrice",
//     type: "float",
//   },
//   {
//     title: "Quantity",
//     key: "requestedQuantity",

//     type: "number",
//   },
//   {
//     title: "Discount",
//     key: "discountRate",
//     type: "number",
//   },
//   {
//     title: "Dis.Amount",
//     key: "discountAmount",
//     type: "float",
//   },

//   { title: "Tax Rate", key: "taxRate", type: "float", currency: false },
//   {
//     title: "Tax Amount",
//     key: "taxAmount",
//     type: "float",
//   },
//   {
//     title: "Total",
//     key: "totalAmount",
//     type: "float",
//   },
// ];
const printfooters = [
  {
    title: "Sub Total",
    hasCurrency: true,
    key: "itemsTotal",
    titleClass: "subtotal",
    valueClass: "subtotalamount",
  },
  {
    title: "Table Price(+)",
    hasCurrency: true,
    key: "tablePrice",
    titleClass: "tablePrice",
    valueClass: "tablePriceamount",
  },

  {
    title: "Charges(+)",
    hasCurrency: true,
    key: "otherCharges",
    titleClass: "discount",
    valueClass: "discount",
  },

  {
    title: "Discount(-)",
    hasCurrency: true,
    key: "discount",
    titleClass: "discount",
    valueClass: "discount",
  },

  {
    title: "Grand Total",
    hasCurrency: true,
    key: "grandTotal",
    titleClass: "grand",
    valueClass: "grandtotal",
  },
];

const Header = ({
  orderNumber,
  branchOrderNumber,
  customerName,
  customerMobile,

  resName,
  orderDate,
  paymentType,
  logo,
  tableNumber,
  orderType,
  gstNumber,
  fssaiNumber,
  branchAddress,
}) => {
  return (
    <>
      <div class="centered">
        {logo && <img src={logo} class="logo" />}
        <p>{resName}</p>
        {branchAddress && <p class="gstNumber">{branchAddress}</p>}
        {fssaiNumber && <p class="gstNumber">FSSAI Number {fssaiNumber}</p>}
      </div>

      <div className="info">
        {customerName && (
          <div className="subinfo">
            <p>
              Name : {customerName}{" "}
              {customerMobile ? `(${customerMobile})` : ""}
            </p>
          </div>
        )}
        <div className="subinfo">
          <p>{branchOrderNumber}</p>
          <p>{moment(orderDate).format("DD/MM/YYYY HH:mm")}</p>
        </div>
        <div className="subinfo">
          <p>{orderType}</p>
          <p>{orderNumber}</p>

          <p>{tableNumber}</p>

          <p>{paymentType}</p>
        </div>
      </div>
    </>
  );
};

const renderRow = (data, value) => {
  return value ? (
    <tr>
      <td colspan="3" class={data.titleClass}>
        {data.title}
      </td>
      <td colspan="3" class={data.valueClass}>
        {data.hasCurrency ? "₹" : " "} {Math.ceil(value)}
      </td>
    </tr>
  ) : (
    ""
  );
};

const renderItem = (value, index, i, tableType) => {
  return (
    <React.Fragment key={index}>
      <tr>
        <td class="pro">{value.itemName}</td>
        <td class="quantity">{value.quantity}</td>
        <td class="price">{price(tableType?.tableType, value) || value.itemPrice}</td>
        <td class="amount">{ (value.quantity * price(tableType?.tableType, value)) || value.itemTotal}</td>
      </tr>
    </React.Fragment>
  );
};

const renderTaxItem = (orderData, tableType) => {
  let itemTotal = 0;
  orderData.map((value) => {
    itemTotal += (value.quantity * price(tableType?.tableType, value)) || value.itemTotal;
  });
  return (
    <React.Fragment>
      <tr>
        <td class="pro">{ itemTotal - (itemTotal * ((orderData[0]?.cgst || 0) + (orderData[0]?.sgst || 0)) / 100) }</td>
        <td class="gst">{itemTotal * (orderData[0]?.cgst || 0) / 100}</td>
        <td class="gst">{itemTotal * (orderData[0]?.sgst || 0) / 100}</td>
      </tr>
    </React.Fragment>
  );
};

const ProductTable = ({ orderData, tableType }) => {
  return (
    <table class="postable">
      <thead>
        <tr>
          <th class="pro">Item</th>
          <th class="quantity">Qty</th>
          <th class="price">Price</th>
          <th class="amount">Amt</th>
        </tr>
      </thead>
      <tbody>
        {orderData.orderItems.map((value, index) => {
          const i = ++index;
          return renderItem(value, index, i, tableType);
        })}

        {printfooters.map((foot, footIndex) =>
          renderRow(foot, orderData[foot.key])
        )}
      </tbody>
    </table>
  );
};

const TaxSummary = ({ orderData, tableType, gst }) => {
  return (
    <table class="postable">
      <thead>
        <tr>
          <th class="pro">Taxable Amt</th>
          <th class="gst">{`CGST@${gst}%`}</th>
          <th class="gst">{`SGST@${gst}%`}</th>
        </tr>
      </thead>
      <tbody>
        {
          renderTaxItem(orderData, tableType)
        }
      </tbody>
    </table>
  );
};

const Invoice = React.forwardRef(
  (
    {
      restaurant,
      orderData,
      count,
      logo,
      gstNumber,
      fssaiNumber,
      receiptMessage,
      branchAddress,
      printSetting,
      tableType,
    },
    ref
  ) => {
    const {
      branchOrderNumber,
      customerName,
      customerMobile,

      orderNumber,
      paymentType,
      updatedAt,
      tableNumber,
      orderType,
    } = orderData;
    // console.log(tableType);
    const gstMap = new Map();
    orderData.orderItems.map((item) => {
      if(!gstMap.has(item.cgst)){
        gstMap.set(item.cgst, []);
      }

      let itemList = gstMap.get(item.cgst);
      itemList.push(item);
      gstMap.set(item.cgst, itemList);
    })

    const keys = [...gstMap.keys()]

    return (
      <div class="ticket" ref={ref}>
        <Header
          resName={restaurant}
          branchOrderNumber={branchOrderNumber}
          orderNumber={orderNumber}
          customerName={printSetting.enableCustomer && customerName}
          customerMobile={customerMobile}
          paymentType={paymentType}
          orderDate={updatedAt}
          tableNumber={tableNumber}
          orderType={orderType}
          logo={logo}
          gstNumber={gstNumber}
          fssaiNumber={fssaiNumber}
          branchAddress={branchAddress}
        />
        <ProductTable orderData={orderData} tableType={tableType} />

        {gstNumber && <div class="centered" style={{paddingTop: "40px"}}>
          <p>{"Tax Summary"}</p>
          <p class="gstNumber">GST {gstNumber}</p>
        </div>}

        {gstNumber && keys.map((key) => {
          if(key !== 0)
            return <TaxSummary orderData={gstMap.get(key)} tableType={tableType} gst={key}/>
        })}
        {receiptMessage && (
          <p class="centered">
            <p>{receiptMessage}</p>
          </p>
        )}
      </div>
    );
  }
);

class BillComponent extends React.Component {
  render() {
    const {
      logo,
      customAction,
      orderData,
      count,
      restaurant,
      gstNumber,
      fssaiNumber,
      receiptMessage,
      branchAddress,
      printSetting,
      tableType,
    } = this.props;
    // console.log("orderData", orderData);
    return orderData ? (
      <Invoice
        count={count}
        restaurant={restaurant}
        orderData={orderData}
        logo={logo}
        gstNumber={gstNumber}
        fssaiNumber={fssaiNumber}
        receiptMessage={receiptMessage}
        branchAddress={branchAddress}
        printSetting={printSetting}
        tableType={tableType}
      />
    ) : (
      <div className={`invoice-box invoice-box-hide" `}>
        No Data found
        {/* <SmartTable
          showHeader={false}
          headerData={tableHeaders}
          tableData={data || []}
          selectable={false}
          small={true}
          title={"title"}
          showActions={false}
          disablePagination
          rowsPerPage={data.length}
          customAction={customAction}
        /> */}
      </div>
    );
  }
}
export default BillComponent;

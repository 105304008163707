export const generateRawMaterialData = () => {
    return {
        type: 'GENERATE_RAW_MATERIAL_DATA',
        payload: {
          request: {
            url: '/api/restaurant/tempsummary/generateDailyRawMaterail',
            method: "get",
          },
        },
    }
};

export const generateOrderSummaryData = () => {
    return {
        type: 'GENERATE_ORDER_SUMMARY_DATA',
        payload: {
          request: {
            url: '/api/restaurant/tempsummary/generateOrderSummary',
            method: "get",
          },
        },
    }
};

export const generateItemSummaryData = () => {
    return {
        type: 'GENERATE_ITEM_SUMMARY_DATA',
        payload: {
          request: {
            url: '/api/restaurant/tempsummary/generateItemSummary',
            method: "get",
          },
        },
    }
};

export const moveTodayOrderToOrder = () => {
    return {
        type: 'MOVE_TODAY_ORDER_TO_ORDER',
        payload: {
          request: {
            url: '/api/restaurant/tempsummary/moveTodayOrderToOrder',
            method: "get",
          },
        },
    }
};

export const moveTodayExpensetoExpense = () => {
    return {
        type: 'MOVE_TODAY_EXPENSE_TO_EXPENSE',
        payload: {
          request: {
            url: '/api/restaurant/tempsummary/moveTodayExpensetoExpense',
            method: "get",
          },
        },
    }
};

export const deleteTodayOrders = () => {
    return {
        type: 'DELETE_TODAY_ORDERS',
        payload: {
          request: {
            url: '/api/restaurant/tempsummary/deleteTodayOrders',
            method: "get",
          },
        },
    }
};

export const deleteTodayExpenses = () => {
    return {
        type: 'DELETE_TODAY_EXPENSES',
        payload: {
          request: {
            url: '/api/restaurant/tempsummary/deleteTodayExpenses',
            method: "get",
          },
        },
    }
};

export const generateDailyBalance = () => {
  return {
      type: 'GENERATE_DAILY_BALANCE',
      payload: {
        request: {
          url: '/api/restaurant/tempsummary/generateDailyBalance',
          method: "get",
        },
      },
  }
};

export const databaseBackups = () => {
  return {
      type: 'DATABASE_BACKUPS',
      payload: {
        request: {
          url: '/api/restaurant/tempsummary/databaseBackup',
          method: "get",
        },
      },
  }
};

export const getOrderSummaryData = (data) => {
    return {
        type: 'GET_ORDER_SUMMARY_DATA',
        payload: {
          request: {
            url: '/api/restaurant/tempsummary/',
            method: "post",
            data: data
          },
        },
    }
};
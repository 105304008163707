import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmOrder,
  deleteLocalOrder,
  setDiscount,
  setKOTitemsData,
  setOtherCharges,
  updateOrder,
  MergeTableOrder,
  getSocketOrders,
  calculateKOT,
  setKOTinSocket,
  prePrintOrder,
  printOrder,
  setOrderConfermation,
} from "../../../redux/action/orderActions";
import OrderButton from "./OrderButton";
import OrderConfirmModal from "../../../components/common/Modals/OrderConfirmModal";
import {
  setKOTPrintData,
  setPrintData,
} from "../../../redux/action/utilActions";
import moment from "moment";
import { uuid } from "uuidv4";
import {
  CURRENCY,
  DATETIMEFORMAT,
  ITEMSTATUS,
  TYPESOFORDERS,
} from "../../../contants";
import calculateOrderTotals from "../../../helpers/calculateOrderTotals";
import PaymentSettleModal from "../../../components/common/Modals/PaymentSettleModal";
import { findActiveOrderIndex } from "../../../redux/reducers/newOrderReducer";
import calculateBranchOrderNumber from "../../../helpers/calculateBranchOrderNumber";
import MergeTableModal from "../../../components/common/Modals/MergeTableModal";
import { deleteLocalOrderOffline } from "../../../redux/action/offlineDataActions";
const getPaymentType = (types) => {
  console.log("getPaymentType", types);
  return types
    .map((t) => {
      return t.paymentMethodType;
    })
    .join(", ");
};
function parseFloat2Decimals(value) {
  return parseFloat(parseFloat(value).toFixed(2));
}
const styles = {
  rightContent: {
    color: "#000",
    textAlign: "right",
  },
  input: {
    textAlign: "right",

    width: "100%",
    height: "25px",
    lineHeight: "1px",
    border: "1px solid blue",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  colTitle: {
    fontWeight: 500,
    color: "#424242",
  },
  colValue: {
    color: "#424242",
  },
  colInput: {
    width: "50%",
  },
  grandTitle: {
    color: "#f0583c",
    fontWeight: 800,
  },
  grandValue: {
    color: "#006400",
    fontWeight: 600,
    fontSize: 20,
  },
};
const OrderTotalDisplay = () => {
  const dispatch = useDispatch();
  // const [discount, setDiscount] = React.useState(0);
  // const [otherCharges, setOtherCharges] = React.useState(0);
  const [orderConfirmOpen, setOrderConfirmOpen] = React.useState();
  const [prePrintOpen, setPrePrintOpen] = React.useState();

  const [KOTModalOpen, setKOTModalOpen] = React.useState(false);
  const [settleOpen, setSettleOpen] = React.useState();
  const [openMergeTable, setOpenMergeTable] = React.useState(false);
  const isElectron = window?.api?.isElectron;
  const {
    activeOrder: refId,
    activeOrders,
    lastOrderNumber,
  } = useSelector((state) => state.order);

  const { enableCustomer, enableRemark } = useSelector((state) => state.util);
  const { isOnline, isAppOffline } = useSelector(
    (state) => state.networkStatus
  );
  const active = activeOrders.filter((order) => order.refId !== refId);
  // const currentOrderRef =
  //   activeOrders[findActiveOrderIndex(activeOrders, activeOrder)];
  // const currentOrder = currentOrderRef[activeOrder];

  const orderIndex = findActiveOrderIndex(activeOrders, refId);
  const currentOrder = activeOrders[orderIndex];
  const { name, restaurantId, branchId, branchCode, cgst, sgst } = useSelector(
    (state) => state.user
  );

  const { tables } = useSelector((state) => state.branch);
  let tableType = tables.filter((table) => {
    return table?.tableNumber === currentOrder?.tableNumber;
  });
  // console.log(tableType[0]?.tableType);
  if (currentOrder?.orderType === "Home Delivery") {
    tableType = [{ tableType: "Online" }];
  }

  const { enableKOT, enablePrinting, enableKDS } = useSelector(
    (state) => state.util
  );

  const discount = currentOrder?.discount || 0;
  const otherCharges = currentOrder?.otherCharges || 0;
  const customerName = currentOrder?.customerName;
  const customerMobile = currentOrder?.customerMobile;
  const orderNumberCount = useSelector((state) => state.order.orderNumberCount);
  // React.useEffect(() => {
  //   setOtherCharges(0);
  //   setDiscount(0);
  // }, [index]);

  const calKOT = () => {
    return calculateKOT({
      active: currentOrder,
      lastOrderNumber,
      activeOrders,
    });
  }

  const printKOT = (customerData) => {
    const { kotItems, kotData } = calKOT();

    if (kotItems?.length > 0 && enableKOT) {
      // dispatch(
      //   setKOTPrintData({
      //     ...kotData,
      //     ...customerData,
      //   })
      // );

      kotItems.forEach((dataitem) => {
        const foundmyItem = currentOrder.orderItems.findIndex(
          (item) => item.itemId === dataitem.itemId
        );
        currentOrder.orderItems[foundmyItem].itemStatus = ITEMSTATUS[1].key;
        currentOrder.orderItems[foundmyItem].itemStatusId = ITEMSTATUS[1].value;
        currentOrder.orderItems[foundmyItem].kotQuantity =
          currentOrder.orderItems[foundmyItem].quantity;
      });
      currentOrder.KOTS.push({
        id: uuid(),
        status: ITEMSTATUS[1].key,
        statusId: ITEMSTATUS[1].value,
        orderItems: kotItems,
      });

      if (!isAppOffline && isOnline) {
        setKOTinSocket(refId, currentOrder);
      }
    }
  };

  const handleCloseSettleModal = () => {
    setSettleOpen();
  };

  const handleOpenSettleModal = (data) => {
    setSettleOpen(data);
  };

  const toggleOrderConfirmModal = () => {
    setOrderConfirmOpen(!orderConfirmOpen);
  };

  const toggleKOTConfirmModal = () => {
    setKOTModalOpen(!KOTModalOpen);
  };

  const toggleMergeATableModal = () => {
    setOpenMergeTable(!openMergeTable);
  };

  const handleOpenMdoal = (type) => {
    if (currentOrder.orderItems.length > 0) {
      printKOT({
        customerData: {
          customerName: customerName,
          customerMobile: customerMobile,
        },
      });
      onConfirmOrder();
    } else {
      alert("No Items selected");
    }
  };

  const handleKOTButtonClick = () => {
    if (!currentOrder) {
      return alert("No active order");
    }

    if (!enableCustomer && !enableRemark) {
      dispatch(setKOTitemsData({ customerData: {} }));
    }
    // setKOTModalOpen(kotData);
    toggleKOTConfirmModal();
  };

  const handleMergeATableOpen = () => {
    if (!currentOrder) {
      return alert("No active order");
    }
    toggleMergeATableModal();
  };

  const handleMergeTable = (orders) => {
    dispatch(MergeTableOrder(orders, refId));
    // console.log(activeOrders);
    dispatch(getSocketOrders(activeOrders));
    setOpenMergeTable();
  };

  const handlePrePrintOpen = () => {
    if (!currentOrder) {
      return alert("No active order");
    }
    if(currentOrder.orderItems.length === 0){
      return alert("No item selected")
    }
    const { kotItems } = calKOT();
    // console.log("length: ", kotItems.length)
    if(kotItems?.length > 0){
      return alert("Click on KOT first")
    }
    if (currentOrder.orderItems.length > 0) {
      printKOT({
        customerData: {
          customerName: customerName,
          customerMobile: customerMobile,
        },
      });
    }
    setPrePrintOpen(true);
    if (!enableCustomer || customerName || customerMobile) {
      handleConfirmOrder();
    }
  };

  const handlePrint = () => {
    dispatch(setPrintData({ ...currentOrder, printId: uuid() }));
  };

  const handlePrintKOT = () => {
    const { kotItems, kotData } = calKOT();

    if (kotItems?.length > 0) {
      dispatch(
        setKOTPrintData({
          ...kotData,
        })
      )
    }
    else{
      dispatch(setKOTPrintData({
        ...currentOrder,
      }))
    }
  }

  const handleConfirmKOTOrder = (customerData) => {
    dispatch(setKOTitemsData({ customerData, tableType }));
    toggleKOTConfirmModal();
  };

  const handleConfirmOrder = (customerData, paymentData, others, cb) => {
    let orderdata = {
      ...currentOrder,
      ...getData(),
      otherCharges: parseFloat2Decimals(getData().otherCharges),
      discount: parseFloat2Decimals(getData().discount),

      grandTotal: Math.ceil(getData().grandTotal),
      orderItems: currentOrder.orderItems,
      orderBy: name,

      paymentType: undefined,
      paymentTypeId: undefined,
      tableNumber: currentOrder.tableNumber,
      tableId: currentOrder._id,
      restaurantId,
      branchId,
      orderNumber: lastOrderNumber + (activeOrders.length - orderIndex),
      branchCode: branchCode,
      orderType: currentOrder.orderType,
      orderTypeId: currentOrder.orderTypeId,

      isPaid: false,
      isBillPrinted: true,

      ...customerData,
      ...paymentData,

      ...others,
    };
    setPrePrintOpen(false);
    if (!isAppOffline && isOnline) {
      dispatch(
        confirmOrder(
          orderdata,
          (data) => {
            setOtherCharges(0);
            setDiscount(0);
            toggleOrderConfirmModal();
            cb && cb();
          },
          tableType
        )
      );
      dispatch(setOrderConfermation(currentOrder));
    }
    // dispatch(
    //   printOrder(orderdata, (data) => {
    //     setOtherCharges(0);
    //     setDiscount(0);
    //     toggleOrderConfirmModal();
    //     cb && cb();
    //   })
    // );
  };

  const onConfirmOrder = (orderData, customerData, paymentData, others) => {
    let orderdata = {
      ...currentOrder,
      ...getData(),
      otherCharges: parseFloat2Decimals(getData().otherCharges),
      discount: parseFloat2Decimals(getData().discount),

      grandTotal: Math.ceil(getData().grandTotal),
      orderItems: currentOrder.orderItems,
      orderBy: name,

      paymentType: undefined,
      paymentTypeId: undefined,
      tableNumber: currentOrder.tableNumber,
      tableId: currentOrder._id,
      restaurantId,
      branchId,
      orderNumber: lastOrderNumber + (activeOrders.length - orderIndex),
      branchCode: branchCode,
      isPaid: false,
      isBillPrinted: true,

      branchOrderNumber: active.branchOrderNumber,
      ...customerData,
      ...paymentData,

      ...others,
      ...(currentOrder?.isEdited && {
        isEdited: true,
        isOrderConfirmed: true,
        isPaid: true,
        _id: currentOrder.id || currentOrder._id,
      }),
    };

    toggleOrderConfirmModal();
    handleOpenSettleModal(orderdata);
  };

  const onSettlePayment = (paymentData, customerData) => {
    if (isAppOffline || !isOnline) {
      window.api.createOrderOffline({
        ...settleOpen,
        ...paymentData,
        ...customerData,
        paymentType: getPaymentType(paymentData.paymentMethods),
        orderStatus: "completed",
        isEdited: false,
        isOrderConfirmed: true,
        isPaid: true,
        branchOrderNumber: currentOrder.branchOrderNumber,
      });

      // dispatch(setPrintData(settleOpen));
      dispatch(deleteLocalOrderOffline(settleOpen.refId));
      setOtherCharges(0);
      setDiscount(0);
      setSettleOpen();
    } else {
      if (!isAppOffline && isOnline && settleOpen.isOrderConfirmed) {
        // if (!settleOpen.isPaid) {
        //   alert("if");
        // } else {
        dispatch(
          updateOrder(
            {
              ...settleOpen,
              ...paymentData,
              ...customerData,
              paymentType: getPaymentType(paymentData.paymentMethods),
              orderStatus: "completed",
              isEdited: false,
              isOrderConfirmed: true,
              isPaid: true,
              _id: currentOrder.id || currentOrder._id,
            },
            (data) => {
              setOtherCharges(0);
              setDiscount(0);
              dispatch(deleteLocalOrder(settleOpen.refId));
              setSettleOpen();
            }
          )
        );
        // }
      } else {
        dispatch(
          confirmOrder(
            {
              ...settleOpen,
              ...paymentData,
              ...customerData,
              paymentType: getPaymentType(paymentData.paymentMethods),
              orderStatus: "completed",
              isEdited: false,
              isOrderConfirmed: true,
              isPaid: true,
            },
            (data) => {
              setOtherCharges(0);
              setDiscount(0);
              dispatch(deleteLocalOrder(settleOpen.refId));
              setSettleOpen();
            }
          )
        );
      }
    }
  };

  const getData = () => {
    return calculateOrderTotals(
      currentOrder,
      cgst,
      sgst,
      otherCharges,
      discount,
      tableType[0]?.tableType
    );
  };

  const rendertableData = [
    [
      {
        title: "SubTotal",
        hasCurrency: true,
        value: getData().subTotal.toFixed(2),
      },
      {
        title: "CGST",
        hasCurrency: true,
        value: getData().cgstCharges.toFixed(2),
      },
      {
        title: "SGST",
        hasCurrency: true,
        value: getData().sgstCharges.toFixed(2),
      },
    ],
    [
      {
        title: "Table Charges",
        hasCurrency: true,
        value: getData().tablePrice,
      },
      {
        title: "O. Charges",
        hasCurrency: true,
        input: () => (
          <input
            disabled={currentOrder?.isOrderConfirmed}
            className={"form-control"}
            maxLength="6"
            style={styles.input}
            value={getData().otherCharges}
            onChange={(e) => {
              if (parseFloat(e.target.value) >= 0) {
                dispatch(setOtherCharges(e.target.value));
              } else {
                dispatch(setOtherCharges(0));
              }
            }}
          />
        ),
      },
      {
        title: "Discount",
        hasCurrency: true,
        input: () => (
          <input
            disabled={currentOrder?.isOrderConfirmed}
            maxLength="6"
            min="0"
            max={"100"}
            className={"form-control"}
            style={styles.input}
            value={discount}
            onChange={(e) => {
              if (
                parseFloat(e.target.value) >=
                parseFloat(getData().grandTotalWithoutDiscount)
              ) {
                alert("Maximum Discount Reached");
                dispatch(
                  setDiscount(
                    parseFloat(getData().discount) +
                      parseFloat(getData().grandTotal)
                  )
                );
              } else {
                console.log("discount else");

                dispatch(setDiscount(e.target.value));
              }
            }}
          />
        ),
      },
    ],
  ];
  return (
    <div class="row">
      <table class="table table-sm table-bordered col-lg-8 mb-0">
        <tbody>
          {rendertableData.map((row, index) => {
            return (
              <tr key={index}>
                {row.map((col, colindex) => {
                  const colInput = col.input;
                  return (
                    <>
                      <td width="25%" key={colindex}>
                        <div style={styles.row}>
                          <div style={styles.colTitle}>{col.title}</div>
                          {col.value && (
                            <div style={styles.colValue}>{col.value}</div>
                          )}
                          {col.input && (
                            <div style={styles.colInput}>{colInput()}</div>
                          )}
                        </div>
                      </td>
                    </>
                  );
                })}
                {index === 0 && (
                  <td rowSpan="2">
                    <div style={styles.row}>
                      <div style={styles.grandTitle}>
                        Grand
                        <br /> Total
                      </div>
                      <div style={styles.grandValue}>
                        {getData().grandTotal}
                      </div>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="col-md-4 mb-0">
        <OrderButton
          enableKDS={enableKDS}
          enableKOT={enableKOT}
          enablePrinting={enablePrinting}
          enableCustomer={enableCustomer}
          onKOTButtonClick={() => handleKOTButtonClick()}
          // onPrePrint={() => handlePrePrintOpen()}
          onPrePrint={() => handlePrePrintOpen()}
          onMergeButtonClick={() => handleMergeATableOpen()}
          onBillPrint={() => handlePrint()}
          onKotPrint={() => handlePrintKOT()}
          onClick={(type) => {
            handleOpenMdoal(type);
          }}
        />
      </div>
      {openMergeTable && (
        <MergeTableModal
          open={openMergeTable}
          tables={active}
          MergeTable={(data) => handleMergeTable(data)}
          onClose={() => setOpenMergeTable()}
        ></MergeTableModal>
      )}
      {settleOpen && (
        <PaymentSettleModal
          open={settleOpen}
          data={settleOpen}
          title={""}
          // onConfirm={(customerData) => handleConfirmOrder(customerData)}
          onClose={() => handleCloseSettleModal()}
          onSubmit={(data, customer) => onSettlePayment(data, customer)}
          customerName={customerName}
          customerMobile={customerMobile}
        />
      )}
      {prePrintOpen && enableCustomer && !customerName && !customerMobile && (
        <OrderConfirmModal
          open={prePrintOpen}
          text={`Grand total : ${CURRENCY} ${getData().grandTotal}`}
          onConfirm={(customerData) => handleConfirmOrder(customerData)}
          onCancel={() => setPrePrintOpen()}
          customerName={customerName}
          customerMobile={customerMobile}
        />
      )}
      {/* {orderConfirmOpen && (
        <OrderConfirmModal
          open={orderConfirmOpen}
          text={`Grand total : ${CURRENCY} ${getData().grandTotal}`}
          onConfirm={(customerData) => {
            onConfirmOrder(orderConfirmOpen, customerData);
          }}
          onCancel={() => toggleOrderConfirmModal()}
          customerName={customerName}
          customerMobile={customerMobile}
        />
      )} */}
      {KOTModalOpen && (enableRemark || enableCustomer) && (
        <OrderConfirmModal
          enableRemarks
          open={KOTModalOpen}
          text={`Confirm Kot`}
          onConfirm={(customerData) => handleConfirmKOTOrder(customerData)}
          onCancel={() => toggleKOTConfirmModal()}
          customerName={customerName}
          customerMobile={customerMobile}
        />
      )}
    </div>
  );
};

export default OrderTotalDisplay;

//other functions
// const handleUpdateOrder = (payment, customerData) => {
//   const paymentData = {
//     paymentType: payment.type,
//     paymentTypeId: payment.id,
//   };
//   if (!enablePrinting) {
//     handleConfirmOrder(customerData, paymentData, {
//       isPaid: true,
//     });
//     return null;
//   }

//   const currentOrder = currentOrder;

//   if (!currentOrder) {
//     toggleOrderConfirmModal();

//     return alert("No active order");
//   }
//   if (
//     currentOrder?.isPaid === false &&
//     currentOrder?.isOrderConfirmed === true
//   ) {
//     const updateData = {
//       refId: currentOrder.refId,

//       restaurantId: currentOrder.restaurantId,

//       _id: currentOrder._id,
//       isPaid: true,
//       ...customerData,
//     };

//     dispatch(
//       updateOrder(updateData, () => {
//         setOtherCharges(0);
//         setDiscount(0);
//         toggleOrderConfirmModal();
//         dispatch(deleteLocalOrder(currentOrder.refId));
//       })
//     );
//   } else {
//     toggleOrderConfirmModal();

//     handleConfirmOrder(
//       customerData,
//       paymentData,
//       {
//         isPaid: false,
//       },
//       () => {
//         dispatch(deleteLocalOrder(currentOrder.refId));
//       }
//     );
//   }
// };

// const onPrePrint = (customer) => {
//   const customerData = {
//     customerName,
//     customerMobile,
//   };
//   let orderdata = {
//     ...getData(),
//     otherCharges: parseFloat2Decimals(getData().otherCharges),
//     discount: parseFloat2Decimals(getData().discount),

//     grandTotal: Math.ceil(getData().grandTotal),
//     orderItems: currentOrder.items,
//     orderBy: name,
//     paymentType: undefined,
//     paymentTypeId: undefined,
//     tableNumber: currentOrder.tableNumber,
//     tableId: currentOrder._id,
//     restaurantId,
//     branchId,
//     orderNumber: lastOrderNumber + (activeOrders.length - orderIndex),
//     branchCode: branchCode,
//     orderType: currentOrder.orderType,

//     ...customerData,
//     ...customer,
//   };
//   dispatch(prePrintOrder(orderdata));
//   setOtherCharges(0);
//   setDiscount(0);
//   setPrePrintOpen(false);
// };

// import React from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { confirmOrder } from "../../../redux/action/orderActions";
// import { showSnackBar } from "../../../redux/action/snackActions";
// import { CURRENCY } from "../../../redux/types";
// import OrderButton from "./OrderButton";
// import OrderConfirmModal from "./OrderConfirmModal";
// const styles = {
//   rightContent: {
//     color: "#000",
//     textAlign: "right",
//   },
//   input: {
//     textAlign: "right",
//     marginLeft: "10px",
//     width: "50%",
//     height: "25px",
//     lineHeight: "1px",
//   },
// };
// const OrderTotalDisplay = () => {
//   const dispatch = useDispatch();
//   const [discount, setDiscount] = React.useState(0);
//   const [otherCharges, setOtherCharges] = React.useState(0);
//   const [orderConfirmOpen, setOrderConfirmOpen] = React.useState();
//   const { name, restaurantId, branchId, branchCode, cgst, sgst } = useSelector(
//     (state) => state.user
//   );

//   const toggleOrderConfirmModal = () => {
//     setOrderConfirmOpen(!orderConfirmOpen);
//   };

//   const handleOpenMdoal = (type) => {
//     if (currentOrder.items.length > 0) setOrderConfirmOpen(type);
//     else {
//       alert("No Items selected");
//     }
//   };

//   const handleConfirmOrder = (payment) => {
//     let orderdata = {
//       ...getData(),
//       grandTotal: Math.ceil(getData().grandTotal),
//       orderItems: currentOrder.items,
//       orderBy: name,
//       paymentType: payment.type,
//       paymentTypeId: payment.id,
//       tableNumber: currentOrder.tableNumber,
//       tableId: currentOrder._id,
//       restaurantId,
//       branchId,
//       orderNumber: lastOrderNumber + (activeOrders.length - index),
//       branchCode: branchCode,
//     };

//     toggleOrderConfirmModal();

//     dispatch(confirmOrder(orderdata))
//       .then((res) => {
//         if (res.payload.status === 200) {
//           dispatch(showSnackBar("Order Successfull"));
//         } else {
//           dispatch(showSnackBar("Failed To Order"));
//         }
//       })

//       .catch((err) => {
//         dispatch(showSnackBar("Failed To Order"));
//       });
//   };

//   const { activeTable: index, activeOrders, lastOrderNumber } = useSelector(
//     (state) => state.order
//   );

//   const getData = () => {
//     let itemsTotal = 0;
//     let cgstCharges = 0;

//     let sgstCharges = 0;
//     let grandTotal = 0;
//     let tablePrice = 0;
//     if (currentOrder) {
//       currentOrder.items.forEach((item) => {
//         itemsTotal += item.itemTotal;
//       });

//       cgstCharges = (itemsTotal * cgst) / 100;

//       sgstCharges = (itemsTotal * sgst) / 100;
//       tablePrice = currentOrder.tablePrice;
//     }
//     grandTotal =
//       itemsTotal +
//       cgstCharges +
//       sgstCharges +
//       tablePrice +
//       parseFloat(otherCharges || 0) -
//       parseFloat(discount || 0);
//     return {
//       itemsTotal,
//       cgstCharges,
//       sgstCharges,
//       otherCharges,
//       discount,
//       tablePrice,
//       grandTotal: grandTotal.toFixed(2),
//     };
//   };
//   return (
//     <div>
//       <div className="row">
//         <div className="col-md-4" />
//         <div className="col-md-8">
//           <table class="table table-sm mb-1">
//             <tbody>
//               <tr>
//                 <td>SubTotal</td>
//                 <td style={styles.rightContent}>
//                   {CURRENCY} {getData().itemsTotal}
//                 </td>
//               </tr>
//               <tr>
//                 <td>CGST</td>
//                 <td style={styles.rightContent}>
//                   {CURRENCY} {getData().cgstCharges}
//                 </td>
//               </tr>
//               <tr>
//                 <td>SGST</td>
//                 <td style={styles.rightContent}>
//                   {" "}
//                   {CURRENCY} {getData().sgstCharges}
//                 </td>
//               </tr>
//               <tr>
//                 <td>Table Charges</td>
//                 <td style={styles.rightContent}>
//                   {CURRENCY} {getData().tablePrice}
//                 </td>
//               </tr>
//               <tr>
//                 <td style={{ whiteSpace: "nowrap" }}>Other Charges</td>
//                 <td className="d-flex justify-content-end">
//                   <input
//                     className={"form-control"}
//                     style={styles.input}
//                     value={getData().otherCharges}
//                     onChange={(e) => setOtherCharges(e.target.value)}
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td>Discount</td>
//                 <td className="d-flex justify-content-end">
//                   <input
//                     className={"form-control"}
//                     style={styles.input}
//                     value={getData().discount}
//                     onChange={(e) => setDiscount(e.target.value)}
//                   />
//                 </td>
//               </tr>

//               <tr>
//                 <td>Grand Total</td>
//                 <td style={styles.rightContent}>
//                   {CURRENCY} {getData().grandTotal}
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-md-12">
//           <OrderButton
//             onClick={(type) => {
//               handleOpenMdoal(type);
//             }}
//           />
//         </div>
//       </div>

//       {orderConfirmOpen && (
//         <OrderConfirmModal
//           text={`Grand total : ${CURRENCY} ${getData().grandTotal}`}
//           onConfirm={() => handleConfirmOrder(orderConfirmOpen)}
//           onCancel={() => toggleOrderConfirmModal()}
//         />
//       )}
//     </div>
//   );
// };

// export default OrderTotalDisplay;

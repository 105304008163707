export const differentPriceField = [
    {
        type: "text",
        name: "priceForAC",
        size: 2,
  
        label: "AC",
        placeholder: "AC Price",
        required: true,
        rules: {
          required: {
            value: true,
            message: "AC Price is required",
          },
        },
      },
  
      {
        type: "text",
        name: "priceForNonAC",
        size: 2,
  
        label: "Non AC",
        placeholder: "Non AC Price",
        required: true,
        rules: {
          required: {
            value: true,
            message: "Non AC Price is required",
          },
        },
      },
  
      {
        type: "text",
        name: "priceForGarden",
        size: 2,
  
        label: "Garden",
        placeholder: "Garden Price",
        required: true,
        rules: {
          required: {
            value: true,
            message: "Garden Price is required",
          },
        },
      },

      {
        type: "text",
        name: "priceForHut",
        size: 2,
  
        label: "Hut",
        placeholder: "Hut Price",
        required: true,
        rules: {
          required: {
            value: true,
            message: "Hut Price is required",
          },
        },
      },

      {
        type: "text",
        name: "priceForBanquetHall",
        size: 2,
  
        label: "Banquet Hall",
        placeholder: "Banquet Hall Price",
        required: true,
        rules: {
          required: {
            value: true,
            message: "Banquet Hall Price is required",
          },
        },
      },

      {
        type: "text",
        name: "priceForRoomService",
        size: 2,
  
        label: "Room Service",
        placeholder: "Room Service Price",
        required: true,
        rules: {
          required: {
            value: true,
            message: "Room Service Price is required",
          },
        },
      },

      {
        type: "text",
        name: "priceForParcel",
        size: 2,
  
        label: "Parcel",
        placeholder: "Parcel Price",
        required: true,
        rules: {
          required: {
            value: true,
            message: "Parcel Price is required",
          },
        },
      },

      {
        type: "text",
        name: "priceForHD",
        size: 2,
  
        label: "Home Delivery",
        placeholder: "Home Delivery Price",
        required: true,
        rules: {
          required: {
            value: true,
            message: "Home Delivery Price is required",
          },
        },
      },
];

// export default differentPriceField;